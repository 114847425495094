body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&.no-select {
		user-select: none;
		cursor: col-resize; /* Or a relevant cursor for resizing */
	}

	.header {
		display: flex;
		flex-direction: row;
		padding: 10px 20px;
		align-items: center;
		background-color: #21617e;
		color: lightgrey;

		.logo {
			margin-right: 40px;
		}

		.user {
			display: flex;
			margin-left: auto;
			justify-content: space-between;
		}
	}

	.content-wrapper {
		display: flex;
		height: calc(100vh - 78px);

		.main-content {
			flex: 1;
			padding: 10px 20px;
			background-color: #f0f0f0;

			.filters-wrapper {
				margin: 10px 0 50px;

				.filters-row {
					// margin-bottom: 15px;

					&-dropdowns-wrapper {
						width: 680px;
						display: inline-block;
						margin-bottom: 15px;

						.form-control { // dropdown
							margin: 0 20px 0 0;
							min-width: 150px;
						}
					}

					&-dates-wrapper {
						width: 340px;
						display: inline-block;
						margin-bottom: 15px;

						.date-picker {
							margin: 0 20px 0 0;
							min-width: 150px;
							width: 150px;
						}
					}
				}
			}

			.table-buttons {
				button {
					margin-left: 10px;
				}
			}

			.no-data-view {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.icon {
					margin: 80px 0 0;
				}

				.text {
					margin: 50px 0;
				}
			}
		}

		.sidebar {
			width: 500px;
			min-width: 350px;
			max-width: 50vw; /* Max width set to 50% of viewport width */
			// background-color: #e4e4e4;
			position: relative;
			display: flex;
			flex-direction: column;
			font-size: 14px;

			.meta-data {
				border: 1px solid #ddd;
				overflow: hidden;
				transition: max-height 0.3s ease;
				max-height: 80px;
				position: relative;
				padding: 10px 10px 30px 20px;

				&-header {
					display: flex;
					flex-direction: column;

					.headline {
						display: flex;
						align-items: center;

						.session-id {
							cursor: pointer;
						}

						.navigation {
							margin-left: auto;

							button + button {
								transform: rotate(180deg);
							}
						}

						.status {
							display: flex;
							text-decoration: underline;
							cursor: pointer;
							margin-left: 10px;
						}

						.tagged {

						}
					}

					.category-chips {
						margin-top: 5px;
					}
				}

				.toggle-button {
					display: flex;
					align-items: center;
					cursor: pointer;
					position: absolute;
					bottom: 5px;
					right: 5px;
				}

				&-content {
					max-height: 0;
					overflow: hidden;
					transition: max-height 0.3s ease, opacity 0.3s ease;
					margin-top: 26px;

					.row {
						display: flex;
						width: 100%;
						margin-bottom: 15px;

						.key {
							width: 100px;
							flex-shrink: 0;
							white-space: nowrap;
							color: #717171;
						}

						.cell {
							width: 250px;

							.key {
								display: inline-block;
								width: 80px;
							}
						}
					}
				}
			}



			.chat-area {
				overflow-y: auto;
				background-color: #ffffff;
				padding: 20px;
			}


			.resizer {
				position: absolute;
				top: 0;
				left: 0;
				width: 3px; /* Thinner width for a sleek appearance */
				height: 100%;
				cursor: ew-resize;
				background-color: #ddd; /* Darker color for contrast */
				opacity: 0.7; /* Slight transparency */
				transition: background-color 0.2s ease; /* Smooth transition on hover */
			}

			.resizer:hover {
				background-color: #c5c5c5; /* Darken on hover for feedback */
				width: 10px;
			}

			.resize-icon {
				display: none;
				position: absolute;
				bottom: -50px;
				left: -15px;
				font-size: medium;
				rotate: 90deg;
				color: #717171;
			}
		}
	}

	.user-management {
		margin: 40px;

		.go-back-button {
			position: absolute;
			right: 40px;
			color: #717171;
		}

		.table-wrapper {
			display: flex;

			.user-management-table {
				padding: 20px 20px 10px;
				width: 50%;
				border: 1px solid #ddd;
				border-radius: 5px;

				.add-button {
					margin-top: 10px;
					margin-left: auto;
					margin-bottom: 10px;
					display: flex;
				}
			}

			.edit-user-area {
				display: flex;
				flex-direction: column;
				padding: 20px;
				border: 1px solid #ddd;
				border-radius: 5px;
				margin-left: 20px;
				width: 50%;
				position: relative;

				.input {
					width: 220px;
					margin-bottom: 40px;
				}

				.action-buttons {
					display: flex;
					justify-content: space-between;
					margin-top: auto;
				}
			}
		}

	}
}
